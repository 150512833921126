<template>
  <v-container fluid class="justify-center fill-height">
  <ValidationObserver v-if="!resetRequestSent" ref="obs" v-slot="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(resetPassword)"
            class="row justify-center align-center">
      <v-col cols="12">
        <div class="title text-center">
          {{ $t('ForgottenPassword.title') }}
        </div>
      </v-col>
      <v-col cols="10" sm="12">
        <v-col cols="12" md="6" class="mx-auto">
          <div class="text-center text--secondary">
            {{ $t('ForgottenPassword.message') }}
          </div>
        </v-col>
        <v-col
          v-for="field in inputFields"
          :key="field.name"
          sm="10"
          cols="12"
          class="pa-0 mx-auto"
        >
          <v-col cols="12" sm="6" class="mx-auto">
            <TextFieldWithValidation
              v-model="field.model"
              :name="field.name"
              outlined
              dense
              mode="eager"
              :label="field.label"
              :rules="field.rules"
              :type="field.type"
            />
            <v-col cols="12" sm="6" class="pl-1">
              <router-link to="/login"
                           style="color: #3aa8cc; text-decoration: none;"
                           class="text-uppercase caption font-weight-bold"
              >{{ $t('ForgottenPassword.toLogin') }}</router-link>
            </v-col>
          </v-col>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-row>
            <v-col cols="8" class="mx-auto" sm="4" md="3">
              <v-btn
                rounded
                block
                color="primary"
                type="submit"
                :loading="loading"
              >{{ $t('ForgottenPassword.confirmBtn') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      </v-form>
  </ValidationObserver>
  <v-row v-else justify="center">
    <v-col cols="12" sm="6">
      <div>
        <v-card-title class="title text-center" style="word-break: break-word;">
          {{ $t('ForgottenPassword.requestSent') }}
        </v-card-title>
        <v-card-text>
          <p class="text-center">
            <v-btn large color="primary" to="/login">{{ $t('ForgottenPassword.toLogin') }}</v-btn>
          </p>
        </v-card-text>
      </div>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ForgottenPassword',
  data() {
    return {
      inputFields: [
        {
          name: 'email',
          model: '',
          rules: 'required|email',
          label: this.$t('inputLabels.email'),
          type: 'email'
        }
      ],
      resetRequestSent: false,
      loading: false
    };
  },
  methods: {
    async resetPassword() {
      try {
        this.loading = true;
        const payload = {};
        this.inputFields.forEach((field) => {
          payload[field.name] = field.model;
        });
        await this.$store.dispatch('user/resetPassword', payload);
        this.resetRequestSent = true;
      } catch (err) {
        this.$snackbar({
          content: `${this.$t('ForgottenPassword.passwordResetRequestError')}: ${err.message}`,
          color: 'error'
        })
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
